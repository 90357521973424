<template>
  <div class="provider-detail-summary-banner px-8">
    <div class="provider-detail-summary-banner__banner pa-4">
      <div
        v-if="!loading"
        class="banner__equivalence">
        <CyCloudCostManagementEmissionEquivalence
          dark
          icon-size="37"
          :value="totals.values.co2e"/>
      </div>
      <div
        v-if="!loading"
        class="banner__period">
        <b>
          {{ $t('cloudCostManagement.totalFor' ) }}
        </b>
        <div class="dates">
          {{ dateRange.begin }} - {{ dateRange.end }}
        </div>
      </div>

      <div
        v-if="!loading"
        class="banner__data">
        <div
          v-for="dataType in ['cost', 'co2e', 'kwh']"
          :key="dataType"
          class="data-item">
          <span
            class="total">
            <span
              class="total-value"
              v-text="formatAmount(totals.values[dataType])"/>
            <span
              class="total-unit"
              v-html="$sanitizeHtml(getUnitByType(dataType))"/>
          </span>
          <div class="trend">
            <CyCloudCostManagementTrendChip
              :value="[totals.oldest[dataType], totals.latest[dataType]]"
              dark/>
          </div>
        </div>
      </div>

      <div
        v-else
        class="provider-detail-summary-banner--loading sk-template">
        <div class="sk-block sk-title sk-primary sk-dark sk-w-18"/>
        <div class="sk-block sk-title sk-primary sk-w-32"/>
      </div>
    </div>
  </div>
</template>

<script>
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip'
import CyCloudCostManagementEmissionEquivalence from '@/components/cloud-cost-management/emission-equivalence'
import { mapState } from 'vuex'
import { formatAmount, getCurrencySymbol } from '@/utils/helpers'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyCloudCostManagementProviderDetailSummaryBanner',
  components: {
    CyCloudCostManagementTrendChip,
    CyCloudCostManagementEmissionEquivalence,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('organization/cloudCostManagement', {
      histogramDates: (state) => state.histogramDates,
      providerData: (state) => state.providerData,
      queryBody: (state) => state.queryBody,
      currency: (state) => state.queryBody.currency,
    }),
    dateRange () {
      const { begin, end } = this.queryBody

      return _.mapValues({
        begin,
        end,
      }, (date) => _.upperFirst(moment(date, 'YYYY-MM-DD').locale(this.$i18n.locale).format('MMM DD, YYYY'))) // eslint-disable-line you-dont-need-momentjs/format
    },
    totals () {
      const beginDate = _.head(this.histogramDates)
      const endDate = _.last(this.histogramDates)

      const oldest = {
        cost: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', beginDate])?.cost || 0),
        kwh: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', beginDate])?.kwh || 0),
        co2e: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', beginDate])?.co2e || 0),
      }

      const latest = {
        cost: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', endDate])?.cost || 0),
        kwh: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', endDate])?.kwh || 0),
        co2e: _.sumBy(this.providerData, ({ values }) => _.find(values, ['date', endDate])?.co2e || 0),
      }

      const values = {
        cost: _.sumBy(this.providerData, ({ values }) => _.sumBy(values, 'cost')),
        kwh: _.sumBy(this.providerData, ({ values }) => _.sumBy(values, 'kwh')),
        co2e: _.sumBy(this.providerData, ({ values }) => _.sumBy(values, 'co2e')),
      }

      return { oldest, latest, values }
    },
  },
  methods: {
    formatAmount,
    getCurrencySymbol,
    getUnitByType (dataType) {
      if (dataType === 'cost') return this.getCurrencySymbol(this.currency)
      return this.$t(`untranslated.${dataType}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-detail-summary-banner {
  display: flex;
  position: sticky;
  z-index: 10;
  bottom: 24px;
  align-items: center;
  justify-content: center;

  &__banner {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 900px;
    max-width: 100%;
    column-gap: 16px;
    border-radius: 4px;
    background-color: get-color("primary");
    box-shadow: 0 0 16px get-color("primary", $alpha: 0.5);
    color: get-color("primary", "light-4");
    justify-items: stretch;
  }

  .banner {
    &__equivalence {
      display: flex;

      ::v-deep .emission-equivalence {
        display: flex;
        align-items: center;
      }
    }

    &__period {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: map.get($font-sizes, "sm");

      .dates {
        color: get-color("primary", "light-3");
      }
    }

    &__data {
      .data-item {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;

        .total {
          display: grid;
          grid-gap: 7px;
          grid-template-columns: minmax(75px, 1fr) 45px;
          align-items: baseline;
          padding-right: 11px;
          color: get-color("primary", "light-5");
          font-size: map.get($font-sizes, "sm");
          font-weight: $font-weight-normal;

          &-value {
            justify-self: right;
          }

          &-unit {
            color: get-color("primary", "light-4");
            font-size: map.get($font-sizes, "sm");
            font-weight: $font-weight-normal;
          }
        }

        &:first-child {
          .total {
            font-size: map.get($font-sizes, "base");
            font-weight: $font-weight-bold;
          }
        }

        .trend {
          justify-self: right;
        }
      }

      .trend-chip {
        font-size: map.get($font-sizes, "sm");
      }
    }
  }

  &--loading {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
