<template>
  <div>
    <span
      :class="['scroll-horizontally', {
        'scroll-horizontally--hidden': !canScrollHorizontally,
      }]">
      {{ $t("scrollHorizontally") }}
    </span>

    <CyBtnToggle
      v-model="dataTypeSelected"
      class="d-flex justify-end pa-4"
      :items="$static.dataType"/>

    <v-data-table
      ref="dataTable"
      :items="loading ? [] : providerData"
      :items-per-page="providerData.length"
      :headers="$static.headers"
      :custom-sort="sortItemsWithType"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="rounded-0"
      :loading="loading"
      :hide-default-header="loading"
      hide-default-footer>
      <template #[`header.canonical`]>
        <span v-if="_.get(queryBody.group_by, 0)">
          {{ $t(`cloudCostManagement.groupBy.${_.camelCase(_.get(queryBody.group_by, 0))}`) }}
        </span>
      </template>
      <template #[`header.values`]>
        <div class="values-row">
          <div
            v-for="date in histogramDates"
            :key="`total-for-${date}`"
            class="values-item grow">
            {{ formatDate(date) }}
          </div>
        </div>
      </template>
      <template #[`body.prepend`]>
        <tr v-if="!loading && providerData.length > 1">
          <td class="sticky-column column-row-expand pr-0">
            &nbsp;
          </td>
          <td
            class="sticky-column column-canonical pl-10"
            v-text="unitSymbolText"/>
          <td class="px-0">
            <div class="values-row">
              <div
                v-for="date in histogramDates"
                :key="date"
                class="values-item cost-cell grow clickable"
                :active="itemIsActive && _.isEqual(['total', getValuesByDate(totals, date), date], itemSelected)"
                @click="selectItem({
                  valueId: 'total',
                  values: getValuesByDate(totals, date),
                  period: date,
                })">
                {{ formatAmount(getValuesByDate(totals, date)[dataTypeSelected]) }}
              </div>
            </div>
          </td>
          <td
            class="sticky-column column-total text-right clickable"
            :active="itemIsActive && _.isEqual(['totals', getTotals(totals), histogramDates], itemSelected)"
            @click="selectItem({
              valueId: 'totals',
              values: getTotals(totals),
              period: histogramDates,
              trendValues: getRowTrend({ values: totals }),
            })">
            {{ formatAmount(_.sumBy(totals, dataTypeSelected)) }}
          </td>
          <td
            v-if="false"
            class="sticky-column column-trend pr-5 text-right">
            <CyCloudCostManagementTrendChip
              :value="getRowTrend({ values: totals }, dataTypeSelected)"/>
          </td>
        </tr>
      </template>
      <template #item="{ item }">
        <tr>
          <td class="sticky-column column-row-expand pr-0">
            <v-icon
              v-if="item.children"
              class="clickable"
              @click="handleRowExpand(item, item.canonical)">
              {{ _.includes(expanded, item.canonical) ? 'expand_less' : 'expand_more' }}
            </v-icon>
          </td>
          <td
            :class="[
              'sticky-column',
              'column-canonical',
              { clickable: item.children },
            ]"
            @click="handleRowExpand(item, item.canonical)">
            <v-badge
              class="column-canonical--badge"
              :color="getItemColor(item.canonical)"
              inline
              left/>
            <CyTooltip
              open-delay="250"
              top>
              <template #activator="{ on }">
                <span
                  class="column-canonical__text"
                  v-on="on">
                  {{ getDisplayName(item.canonical, _.camelCase(mainGroupingParam)) }}
                </span>
              </template>
              {{ getDisplayName(item.canonical, _.camelCase(mainGroupingParam)) }}
            </CyTooltip>
          </td>
          <td class="px-0">
            <div class="values-row">
              <div
                v-for="date in histogramDates"
                :key="`value-for-${date}`"
                class="values-item cost-cell grow clickable"
                :active="itemIsActive && _.isEqual([item.canonical, getValuesByDate(item.values, date), date], itemSelected)"
                @click="selectItem({
                  valueId: item.canonical,
                  values: getValuesByDate(item.values, date),
                  period: date,
                  canonical: getDisplayName(item.canonical, _.camelCase(mainGroupingParam)),
                  canonicalColor: getItemColor(item.canonical),
                })">
                {{ formatAmount(getValuesByDate(item.values, date)[dataTypeSelected]) }}
              </div>
            </div>
          </td>
          <td
            class="sticky-column column-total text-right clickable"
            :active="itemIsActive && _.isEqual([item.canonical, getTotals(item.values), histogramDates], itemSelected)"
            @click="selectItem({
              valueId: item.canonical,
              values: getTotals(item.values),
              period: histogramDates,
              trendValues: getRowTrend(item),
              canonical: getDisplayName(item.canonical, _.camelCase(mainGroupingParam)),
              canonicalColor: getItemColor(item.canonical),
            })">
            {{ formatAmount(_.sumBy(item.values, dataTypeSelected)) }}
          </td>
          <td
            v-if="false"
            class="sticky-column column-trend text-right">
            <CyCloudCostManagementTrendChip
              :value="getRowTrend(item, dataTypeSelected)"/>
          </td>
        </tr>
        <tr
          v-if="!_.isEmpty(item.children)"
          class="second-level-row">
          <td
            colspan="7"
            class="pa-0 panels-wrapper">
            <v-expansion-panels :value="_.includes(expanded, item.canonical) ? 0 : null">
              <v-expansion-panel>
                <v-expansion-panel-content
                  v-for="child in sortItemsWithType(item.children, [sortBy], [sortDesc])"
                  :key="`${item.canonical}__${child.canonical}`"
                  class="second-level-detail"
                  eager>
                  <span class="sticky-column column-row-expand"/>
                  <span class="sticky-column column-canonical">
                    <CyTooltip
                      open-delay="250"
                      top>
                      <template #activator="{ on }">
                        <span
                          class="column-canonical__text"
                          v-on="on">
                          {{ getDisplayName(child.canonical, _.camelCase(secondaryGroupingParam)) }}
                        </span>
                      </template>
                      {{ getDisplayName(child.canonical, _.camelCase(secondaryGroupingParam)) }}
                    </CyTooltip>
                  </span>
                  <div class="px-0 values-row grow">
                    <div
                      v-for="date in histogramDates"
                      :key="date"
                      class="values-item cost-cell grow clickable"
                      :active="itemIsActive && _.isEqual([item.canonical+child.canonical, getValuesByDate(child.values, date), date], itemSelected)"
                      @click="selectItem({
                        valueId: item.canonical+child.canonical,
                        values: getValuesByDate(child.values, date),
                        period: date,
                        canonical: getDisplayName(item.canonical, _.camelCase(mainGroupingParam)),
                        canonicalColor: getItemColor(item.canonical),
                        childCanonical: getDisplayName(child.canonical, _.camelCase(secondaryGroupingParam)),
                      })">
                      {{ formatAmount(getValuesByDate(child.values, date)[dataTypeSelected]) }}
                    </div>
                  </div>
                  <span
                    class="sticky-column column-total cost-cell clickable"
                    :active="itemIsActive && _.isEqual([item.canonical+child.canonical, getTotals(child.values), histogramDates], itemSelected)"
                    @click="selectItem({
                      valueId: item.canonical+child.canonical,
                      values: getTotals(child.values),
                      period: histogramDates,
                      trendValues: [_.head(child.values), _.last(child.values)],
                      canonical: getDisplayName(item.canonical, _.camelCase(mainGroupingParam)),
                      canonicalColor: getItemColor(item.canonical),
                      childCanonical: getDisplayName(child.canonical, _.camelCase(secondaryGroupingParam)),
                    })">
                    {{ formatAmount(_.sumBy(child.values, dataTypeSelected)) }}
                  </span>
                  <span
                    v-if="false"
                    class="sticky-column column-trend justify-end">
                    <CyCloudCostManagementTrendChip
                      :value="[_.head(child.values)[dataTypeSelected], _.last(child.values)[dataTypeSelected]]"/>
                  </span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </tr>
      </template>
      <template #loading>
        <div class="sk-template d-flex flex-column py-3">
          <div
            v-for="i in 3"
            :key="`skeleton-row-${i}`"
            class="d-flex justify-space-between pa-3">
            <div class="d-flex align-center">
              <div class="sk-block sk-avatar sk-w-5 sk-h-5 mr-4"/>
              <div class="sk-block sk-title sk-dark sk-w-28"/>
            </div>
            <div class="d-flex align-center">
              <div class="sk-block sk-title sk-w-6 mr-8"/>
              <div class="sk-block sk-title sk-w-16 mr-8"/>
              <div class="sk-block sk-title sk-w-10 mr-8"/>
              <div class="sk-block sk-title sk-w-20"/>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip'
import CyBtnToggle from '@/components/btn-toggle'
import { mapState, mapMutations } from 'vuex'
import { formatAmount, getCurrencySymbol } from '@/utils/helpers'
import { getDisplayName, graphColors, sortItems } from '@/utils/helpers/cloud-cost-management'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyCloudCostManagementProviderDataTable',
  components: {
    CyCloudCostManagementTrendChip,
    CyBtnToggle,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    topItems: {
      type: Array,
      default: () => [],
    },
    totals: {
      type: Array,
      default: () => [],
    },
    itemIsActive: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dataTableElInt: null,
    expanded: [],
    hasTableHorizontalScroll: false,
    sortBy: 'current_total',
    sortDesc: true,
    itemSelected: '',
    dataTypeSelected: 'cost',
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      currency: (state) => state.queryBody.currency,
      providerData: (state) => state.providerData,
      histogramDates: (state) => state.histogramDates,
      queryBody: (state) => state.queryBody,
      mainGroupingParam: (state) => state.queryBody.group_by[0],
      secondaryGroupingParam: (state) => state.queryBody.group_by[1],
    }),
    $static () {
      return {
        headers: [
          {
            text: '',
            align: 'left',
            value: 'data-table-expand',
            width: '50px',
            sortable: false,
            class: ['sticky-column', 'column-row-expand'],
          },
          {
            text: '',
            align: 'right',
            value: 'canonical',
            sortable: _.get(this.providerData[0], 'canonical', null),
            width: '200px',
            class: ['sticky-column', 'column-canonical', 'pl-5'],
          },
          {
            text: '',
            align: 'right',
            value: 'values',
            width: '100%',
            sortable: false,
            class: 'px-0',
          },
          {
            text: this.headerText,
            align: 'right',
            value: 'current_total',
            width: '115px',
            class: ['sticky-column', 'column-total', 'pr-0'],
          },
          // {
          //   text: '',
          //   align: 'right',
          //   value: 'previous_total',
          //   width: '130px',
          //   sortable: false,
          //   class: ['sticky-column', 'column-trend', 'pr-2'],
          // },
        ],
        dataType: [
          {
            text: `${this.getCurrencySymbol(this.currency)} - ${this.currency}`,
            key: 'cost',
            value: 'cost',
            tooltip: this.$t('cloudCostManagement.cost'),
          },
          {
            text: 'KgCO2e',
            key: 'co2e',
            value: 'co2e',
            tooltip: this.$t('cloudCostManagement.emissions'),
          },
          {
            text: 'kWh',
            key: 'kwh',
            value: 'kwh',
            tooltip: this.$t('cloudCostManagement.energy'),
          },
        ],
      }
    },
    canScrollHorizontally () {
      return !this.loading && this.hasTableHorizontalScroll
    },
    headerText () {
      if (this.dataTypeSelected === 'cost') return `${this.$t('cloudCostManagement.cost')} (${this.getCurrencySymbol(this.currency)})`
      else if (this.dataTypeSelected === 'co2e') return 'KgCO2e'
      return 'kWh'
    },
    unitSymbolText () {
      if (this.dataTypeSelected === 'cost') return `${this.$t('untranslated.total')} (${this.getCurrencySymbol(this.currency)})`
      else if (this.dataTypeSelected === 'co2e') return `${this.$t('untranslated.total')} (KgCO2e)`
      return `${this.$t('untranslated.total')} (kWh)`
    },
  },
  watch: {
    dataTypeSelected (value) {
      this.SET_GRAPH_ORDERING_PARAM(value)
    },
  },
  mounted () {
    // Periodically checks for data table horizontal scroll bar,
    // storing a boolean value of its state in `hasTableHorizontalScroll`
    // to make so we know when to display the "Scroll horizontally" tag
    this.dataTableElInt = window.setInterval(() => {
      const dataTableEl = this.$refs.dataTable?.$el || {}
      const tableWrapper = dataTableEl.getElementsByClassName('v-data-table__wrapper')[0]
      this.hasTableHorizontalScroll = tableWrapper.scrollWidth > tableWrapper.clientWidth
    }, 1000)
  },
  destroyed () {
    this.SET_GRAPH_ORDERING_PARAM('cost')
    window.clearInterval(this.dataTableElInt)
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_GRAPH_ORDERING_PARAM',
    ]),
    formatAmount,
    getCurrencySymbol,
    getDisplayName,
    sortItems,
    sortItemsWithType (items, sortBy, sortDesc) {
      return this.sortItems(items, sortBy, sortDesc, this.dataTypeSelected)
    },
    formatDate (date) {
      return moment(date).format('MMM D') // eslint-disable-line you-dont-need-momentjs/format
    },
    selectItem () {
      const { valueId, values, period } = _.head(arguments)

      this.itemSelected = [valueId, values, period]

      this.$emit('selected', ...arguments)
    },
    getValuesByDate (values, date) {
      const cost = _.get(_.find(values, ['date', date]), 'cost', 0)
      const co2e = _.get(_.find(values, ['date', date]), 'co2e', 0)
      const kwh = _.get(_.find(values, ['date', date]), 'kwh', 0)
      return { cost, co2e, kwh }
    },
    getTotals (values) {
      const cost = _.sumBy(values, 'cost')
      const co2e = _.sumBy(values, 'co2e')
      const kwh = _.sumBy(values, 'kwh')
      return { cost, co2e, kwh }
    },
    // eslint-disable-next-line vue/no-unused-properties
    async scrollTableValues () {
      await this.$nextTick()
      const tableWrapper = this.$refs.dataTable.$el.getElementsByClassName('v-data-table__wrapper')[0]
      tableWrapper.scrollLeft = tableWrapper.scrollWidth
    },
    handleRowExpand (item, canonical) {
      if (!item.children) return
      this.expanded = _.xor(this.expanded, [canonical])
    },
    getItemColor (itemCanonical) {
      const canonical = itemCanonical || 'undefined'
      const itemIndex = _.indexOf(this.topItems, canonical)
      const othersIndex = _.indexOf(this.topItems, this.$t('others'))
      const colorIndex = itemIndex === -1 ? othersIndex : itemIndex
      return itemCanonical === this.$t('untranslated.total') ? 'transparent' : graphColors[colorIndex]
    },
    getRowTrend (item, dataType = null) {
      if (_.isEmpty(item.values)) return [0, 0]
      const earliestDate = _.head(this.histogramDates)
      const latestDate = _.last(this.histogramDates)
      const earliestValue = _.find(item.values, ['date', earliestDate])
      const latestValue = _.find(item.values, ['date', latestDate])
      return [
        dataType ? earliestValue?.[dataType] || 0 : earliestValue,
        dataType ? latestValue?.[dataType] || 0 : latestValue,
      ]
    },
  },
  i18n: {
    messages: {
      en: {
        scrollHorizontally: 'Scroll horizontally',
        totalCost: '@:cloudCostManagement.totalCost',
        trend: 'Trend',
      },
      es: {
        scrollHorizontally: 'Desplazar horizontalmente',
        totalCost: '@:cloudCostManagement.totalCost',
        trend: 'Tendencia',
      },
      fr: {
        scrollHorizontally: 'Défiler horizontalement',
        totalCost: '@:cloudCostManagement.totalCost',
        trend: 'Tendance',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .scroll-horizontally {
    position: absolute;
    z-index: 10;
    top: 64px;
    left: 50%;
    width: auto;
    padding: 0 6px;
    transform: translateX(-25%);
    transition: opacity ease 0.5s;
    border-radius: 0 0 6px 6px;
    opacity: 1;
    background-color: get-color("primary", "light-4");
    box-shadow: 1px 0 2px get-color("black", $alpha: 0.25);
    font-size: map.get($font-sizes, "sm");
    font-weight: $font-weight-normal;

    &--hidden {
      opacity: 0;
    }
  }

  .cost-cell {
    color: get-color("primary");
    white-space: nowrap;
  }

  ::v-deep .values {
    &-row {
      display: flex;
      height: 100%;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100px;
      height: 100%;
      padding: 0 16px;
    }
  }

  ::v-deep.v-data-table {
    position: relative;
    overflow: hidden;
    border-top: 1px solid get-color("grey", "light-1");

    td[active],
    div[active],
    span[active] {
      background: get-color("secondary", "light-4");

      &:hover {
        background: get-color("secondary", "light-3") !important;
      }
    }

    .v-data-table-header {
      th {
        height: auto;
        min-height: 52px;
        background-color: get-color("grey", "light-4");
        font-size: map.get($font-sizes, "base");
        font-weight: $font-weight-medium;

        &:not(.sticky-column) {
          background-color: get-color("grey", "light-3");
        }

        &:not(.active) {
          color: get-color("grey", "dark-2");
        }
      }

      tr:first-child th {
        padding-top: 18px;
        padding-bottom: 12px;
      }
    }

    .column-canonical {
      display: flex;
      align-items: center;
      min-width: 150px;
      max-width: 200px;

      &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .v-badge {
      &__wrapper {
        display: flex;
        margin-right: 17px;
      }

      &__badge {
        width: 6px;
        min-width: 0;
        height: 6px;
        padding: 0;
      }
    }

    .panels-wrapper {
      height: auto;
      border-bottom: 0 !important;
    }
  }

  ::v-deep .v-data-table__wrapper {
    tr {
      position: relative;
      z-index: 1;

      &.second-level-row {
        z-index: 0;
      }
    }

    td:not(.sticky-column) {
      background-color: get-color("grey", "light-4");

      .values-item:hover {
        background-color: get-color("grey", "light-3");
      }
    }

    thead {
      position: sticky;
      z-index: 9;
      top: 0;
    }
  }

  ::v-deep .sticky-column {
    position: sticky;
    z-index: 1;
    background-color: get-color("white");

    &.column {
      &-row-expand {
        left: 0;
      }

      &-canonical {
        left: 50px;

        &::after {
          content: " ";
          position: absolute;
          top: 0;
          right: -3px;
          width: 3px;
          height: 105%;
          background: linear-gradient(90deg, get-color("black", $alpha: 0.12), 40%, transparent);
        }
      }

      &-total {
        right: 0;
        justify-self: end;
        width: 100%;
        font-weight: $font-weight-bold;

        &::before {
          content: " ";
          position: absolute;
          top: 0;
          left: -3px;
          width: 3px;
          height: 105%;
          background: linear-gradient(90deg, transparent, 60%, get-color("black", $alpha: 0.12));
        }
      }

      // &-trend {
      //   right: 0;

      //   .v-chip__content {
      //     font-size: map.get($font-sizes, "sm");
      //   }
      // }
    }
  }

  .second-level-row {
    ::v-deep .v-expansion-panel-content {
      overflow: visible !important; // Prevent issue with sticky columns during transitions.
    }
  }

  .second-level-detail {
    ::v-deep .v-expansion-panel-content__wrap {
      display: grid;
      grid-template-columns: 50px 200px 1fr 115px;
      align-items: center;
      height: 48px;
      padding: 1px 0;
      background-color: get-color("grey", "light-4");

      > * {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 16px;

        &.column-total {
          justify-content: flex-end;
        }
      }
    }

    .sticky-column {
      background-color: get-color("grey", "light-4");
    }

    &:last-child {
      border-bottom: thin solid get-color("black", $alpha: 0.12) !important;
    }
  }
</style>
